<template>
  <section class="policy"
           :class="{ 'margin-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="container">
      <h3 class="title__header">個人情報の取扱いについて</h3>

      <div class="block">
        <h3>1. 個人情報の定義</h3>
        <p>個人情報とは、生存する個人に関する情報で、その情報に含まれる氏名や生年月日等によって特定の個人を識別できる情報、ならびに指紋、DNA、顔の骨格などの身体の特徴データや、マイナンバー、パスポートや運転免許証の番号などの個人識別符号を含む情報を、「個人情報」と認識します。</p>
      </div>

      <div class="block">
        <h3>2. 個人情報利用目的</h3>
        <p>ご提供いただきました個人情報は、以下の目的でのみ利用いたします。</p>
        <ul>
          <li>① お問い合わせに対する回答及び資料送付のご連絡</li>
          <li>② 当社のお客様向けサービスの提供</li>
          <li>③ 当社のお客様向けサービスの利用状況の分析</li>
          <li>④ 本人確認</li>
          <li>⑤ サービスの開発・改善のための分析</li>
          <li>⑥ 当社のお客様向けサービスに関する広告の配信のため</li>
        </ul>
      </div>

      <div class="block">
        <h3>3. 個人情報利用の制限</h3>
        <p>あらかじめご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。ただし、次の場合はこの限りではありません。</p>
        <ul>
          <li>① 法令に基づく場合</li>
          <li>② 人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
          <li>③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
          <li>④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
        </ul>
      </div>

      <div class="block">
        <h3>4. 個人情報の適正な取得</h3>
        <p>個人情報の取得に際しては、利用目的、取扱い範囲を明確にし、ご本人の同意がある場合、適法かつ公正な手段によって取得します。</p>
      </div>

      <div class="block">
        <h3>5. 個人情報の取得に際する利用目的の通知</h3>
        <p>個人情報を取得するにあたり、あらかじめその利用目的を公表します。ただし、次の場合はこの限りではありません。</p>
        <ul>
          <li>① 利用目的をご本人に通知し、または公表することによりご本人または第三者の生命、身体、財産その他の 権利利益を害するおそれがある場合</li>
          <li>② 利用目的をご本人に通知し、または公表することによりChibiJobの権利または正当な利益を害するおそれがある場合</li>
          <li>③ 国の機関もしくは地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的をご本人に通知し、または公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          <li>④ 取得の状況からみて利用目的が明らかであると認められる場合</li>
        </ul>
      </div>

      <div class="block">
        <h3>6. 個人情報利用目的の変更</h3>
        <p>個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、ご本人に通知し、または公表します。</p>
      </div>

      <div class="block">
        <h3>7. 個人情報の安全管理・従業員の監督</h3>
        <p>個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理が図られるよう、個人情報保護規程を定め、従業員に対する必要かつ適切な監督を行います。</p>
      </div>

      <div class="block">
        <h3>8. 委託先の監督</h3>
        <p>個人情報の取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約の締結、または、当社が定める約款に合意を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</p>
      </div>

      <div class="block">
        <h3>9. 第三者提供の制限</h3>
        <p>次に掲げる場合を除くほか、あらかじめご本人の同意を得ないで、個人情報を第三者に提供しません。</p>
        <ul class="mb-3">
          <li>① 法令に基づく場合</li>
          <li>② 人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
          <li>③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
          <li>④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
        </ul>
        <p>ただし次に掲げる場合は上記に定める第三者には該当せず、情報提供される場合があります。</p>
        <ul>
          <li>⑤ 商品・サービスの発送等を委託する配送業者に対し送付先情報として会員情報を開示する場合など利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
        </ul>
      </div>

      <div class="block">
        <h3 class="two-number">10. 個人情報の開示</h3>
        <p>ご本人から、個人情報の開示を求められたときは、ご本人に対し、遅滞なく開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。 なお、アクセスログなどの個人情報以外の情報については、原則として開示いたしません。</p>
        <ul>
          <li>① ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
          <li>② 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
          <li>③ 他の法令に違反することとなる場合</li>
        </ul>
      </div>

      <div class="block">
        <h3 class="two-number">11. 個人情報の訂正等</h3>
        <p>ご本人から、個人情報が真実でないという理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ご本人に通知します。</p>
      </div>

      <div class="block">
        <h3 class="two-number">12. 個人情報の利用停止等</h3>
        <p>ご本人から、ご本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または偽りその他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</p>
      </div>

      <div class="block">
        <h3 class="two-number">13. お問い合わせ</h3>
        <p>ChibiJobのプライバシーポリシーに関するお問い合わせは、下記担当までお願い致します。</p>
        <p>136-0072<br>
          東京都江東区大島1-9-8<br>
          contact@chibijob.com<br>
          株式会社ディー・エム広告社 ChibiJob担当</p>
      </div>

      <div class="block">
        <p class="text-right">
          株式会社ディー・エム広告社<br>
          代表取締役 藤本耕平</p>
      </div>
    </div>
  </section>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'Policy',

  mixins: [Common],

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }
}
</script>

<style lang="scss" scoped>
.policy {
  padding: 30px 0;
  @media #{$info-phone} {
    margin-top: 30px;
  }
  &.padding-has-caution {
      @media #{$info-phone} {
        padding-top: 65px;
      }
    }
  &.margin-webview {
    margin-top: 0;
  }

  .block {
    margin-bottom: 20px;
    h3 {
      color: #7C934C;
      word-break: break-all;
      &.two-number {
        margin-left: -12px;
      }
    }
    p {
      padding-left: 23px;
      margin-bottom: 15px;
      & + ul {
        padding-left: 23px;
        li {
          text-indent: -1.2rem;
          margin-left: 1.2rem;
        }
      }
    }
  }
}

</style>
